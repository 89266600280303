import React from 'react';
import {
  DontDo,
  DontDoItem,
  Link,
  List,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  PageNavigation,
  PageNavigationLink,
  PageHero,
  PageWithSubNav,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Elevation"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Layering"
        tierThree="Elevation"
      />

      <PageNavigation>
        <PageNavigationLink>Stack Order</PageNavigationLink>
        <PageNavigationLink>Levels vs. Layering</PageNavigationLink>
        <PageNavigationLink>Mobile</PageNavigationLink>
        <PageNavigationLink>Usage</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Stack Order">
        <Paragraph>
          Common interface elements are layered on a predetermined elevation
          scale ranging 0-3, with each level indicating importance and priority
          to the user. This stack order is consistent across Hudl interfaces.
        </Paragraph>

        <img
          src={require('../../../../imgs/diagrams/layering-stackorder.gif')}
          className="uni-pad--one--btm"
        />

        <SectionSubhead>Tangents</SectionSubhead>
        <Paragraph>
          A tangent is a piece of content (tooltip, dropdown, popover) that does
          not have a set elevation, but needs to sit above other content in its
          current elevation. A tooltip within a modal (layer 3) is a perfect
          example.
        </Paragraph>

        <img
          src={require('../../../../imgs/diagrams/layering-tangent.png')}
          className="uni-pad--one--btm"
        />

        <SectionSubhead>Scrim</SectionSubhead>
        <Paragraph>
          A scrim is a transparent overlay placed just beneath a layered
          element. Its purpose is to dim and obfuscate content underneath the
          layered element. This focuses the user’s attention on the layered
          element in question. Scrims are required for layer 3 (modals), but
          optional for layer 2 elements like prompts,{' '}
          <Link href="/components/toast" isDesignCodeLink>
            toasts
          </Link>{' '}
          and action bars.
        </Paragraph>
      </Section>

      <Section title="Levels vs. Layering">
        <Paragraph>
          It’s important to distinguish{' '}
          <Link href="/guidelines/colors/themes" isDesignCodeLink>
            theming environment levels
          </Link>{' '}
          from the concept of layering. Levels affect the background and/or
          border color of a given element, whereas layering physically places
          one element on top of another.
        </Paragraph>
        <Paragraph>
          Levels create separation between regions on the same plane, while
          layering creates a new dimension altogether. For instance, you might
          paint your living room and upstairs bedroom the same color, but color
          is where the connection ends. Otherwise they’re totally separate,
          physically and in purpose.
        </Paragraph>
        <Paragraph>
          The dimension of layers is visually accentuated with{' '}
          <Link href="/guidelines/layering/shadows" isDesignCodeLink>
            the shadows
          </Link>{' '}
          applied to each.
        </Paragraph>
      </Section>
      <Section title="Mobile">
        <Paragraph>
          Layering elevation still applies to design on mobile but is approached
          differently depending on the portion of the interface affected. For
          example, <Link href="/components/modal/design">modals</Link> used in
          iOS make use of{' '}
          <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/app-architecture/modality/">
            modal view
          </Link>
          , which applies elevation and{' '}
          <Link href="/guidelines/layering/shadows/design">shadow</Link> to the
          entire view. Whereas Android depicts the layer with a{' '}
          <Link href="https://material.io/design/environment/elevation.html#depicting-elevation">
            scrim
          </Link>
          . Be cognizant of each platform when designing elevation.
        </Paragraph>
      </Section>

      <Section title="Usage">
        <SectionSubhead>Stack Order</SectionSubhead>
        <Paragraph>
          The stack order ensures similar interface elements like toasts, modals
          and action bars have a consistent elevation across all Hudl products.
        </Paragraph>

        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="put elements on the wrong layer."
            img="elevation-wrong-layer-dont"
          />
          <DontDoItem
            type="do"
            text="follow the correct stacking order."
            img="elevation-wrong-layer-do"
          />
        </DontDo>

        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use shadows to “elevate” levels."
            img="elevation-shadows-dont"
          />
          <DontDoItem
            type="do"
            text="apply shadows to layered elements."
            img="elevation-shadows-do"
          />
        </DontDo>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="Follow platform guidelines.">
          <PlatformTableRow platform="web" />
          <PlatformTableRow platform="apple">
            <List>
              <li>
                Follow{' '}
                <Link href="https://developer.apple.com/ios/human-interface-guidelines">
                  Human Interface guidelines
                </Link>
                .
              </li>
            </List>
          </PlatformTableRow>
          <PlatformTableRow platform="android">
            <List>
              <li>
                Follow{' '}
                <Link href="https://material.io/guidelines">
                  Material guidelines
                </Link>
                .
              </li>
            </List>
          </PlatformTableRow>
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
